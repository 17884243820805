<template>
  <div id="app">
    <NuxtLayout>
      <PageContainer>
        <div class="text-primary w-full sm:w-1/3">
          <InlineSvg
            :type="INLINE_SVG_TYPES.ILLUSTRATION"
            :name="ILLUSTRATIONS.POPCORN"
            class="w-full fill-current"
          />
        </div>
        <div class="w-full space-y-4 px-8 sm:w-2/3">
          <PageHeading
            :title="`${error.statusCode} - ${error.statusMessage}`"
            :tag="PAGE_HEADING_TAG.H1"
          />

          <div v-html="ct('ERROR_PAGE_ERROR_TEXT', {}, t('text.error'))" />
          <div v-html="ct('ERROR_PAGE_SUPPORT_TEXT', {}, t('text.support'))" />

          <div class="flex w-52 flex-col items-center space-y-3">
            <Button
              :theme="BUTTON_THEME.PRIMARY"
              class="w-full justify-center"
              :text="t('btn.backHome')"
              @click="handleError"
            />
            <Button
              :theme="BUTTON_THEME.SECONDARY"
              class="w-full justify-center"
              :text="t('btn.support')"
            />
          </div>
        </div>
      </PageContainer>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
interface Props {
  error: {
    statusCode: number
    statusMessage: string
  }
}

const props = defineProps<Props>()

await useAsyncData('app:setup', () => useAppSetup({ addFavicon: true }))

const { currentUrl } = useCurrentUrl()
const { ct, fetchCinemaStrings } = useCinemaStrings()
const { overrideCreateElement } = useConsentManager()
const { t } = useI18n()

if (process.server) {
  await fetchCinemaStrings({
    keys: ['ERROR_PAGE_ERROR_TEXT', 'ERROR_PAGE_SUPPORT_TEXT'],
  })
}

if (process.client) {
  overrideCreateElement()
}

if (props.error.statusCode === 404) {
  const { data: redirect } = await useAsyncData(
    `${props.error.statusCode}-${props.error.statusMessage}`,
    () =>
      GqlFetchCmsRedirect({
        url: currentUrl.value,
      })
  )

  if (redirect.value?.cmsRedirect) {
    await navigateTo(redirect.value.cmsRedirect.toUrl, { external: true })
  }
}

const handleError = () => clearError({ redirect: '/' })

defineOptions({
  name: 'ErrorPage',
})
</script>

<i18n>
de:
  btn:
    backHome: "Zurück zur Startseite"
    support: "kinoheld Support"
  text:
    error: "Leider konnte die gewünschte Seite nicht gefunden werden. Möglicherweise handelt es sich um einen fehlerhaften Link oder die Seite existiert nicht mehr."
    support: "Sollten weiterhin Probleme bestehen oder Sie der Meinung sein, wir sollten dringend von dem Fehler wissen, wenden Sie sich gerne an unseren Support."
es:
  btn:
    backHome: "Volver a la página de inicio"
    support: "Soporte de entradas"
  text:
    error: "Lamentablemente, la página solicitada no se pudo encontrar. Es posible que se trate de un enlace incorrecto o que la página ya no exista."
    support: "Si sigue teniendo problemas o cree que deberíamos saber urgentemente sobre el error, no dude en ponerse en contacto con nuestro soporte."
</i18n>
