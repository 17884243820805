import { default as _91_46_46_46pageSlug_93IV93Z4RmABMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activatedWs5YoHoFXMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/activate.vue?macro=true";
import { default as indexoXD976aAcYMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93kkrZVa31eTMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newSzQjHbACFaMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistpAwc2g74i5Meta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/watchlist.vue?macro=true";
import { default as indexTkTqSVntiOMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as infos9mYMChxH4oMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/infos.vue?macro=true";
import { default as programkLQwrGcQkxMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/program.vue?macro=true";
import { default as indexulhHRAzfxtMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_93PmmccBMJXZMeta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue?macro=true";
import { default as _91movieSlug_93l9TKgrDZ00Meta } from "/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug].vue?macro=true";
export default [
  {
    name: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.name ?? "pageSlug___en",
    path: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.path ?? "/en/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93IV93Z4RmABMeta || {},
    alias: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.name ?? "pageSlug___de",
    path: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.path ?? "/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93IV93Z4RmABMeta || {},
    alias: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.name ?? "pageSlug___es",
    path: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.path ?? "/es/:pageSlug(.*)*",
    meta: _91_46_46_46pageSlug_93IV93Z4RmABMeta || {},
    alias: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.alias || [],
    redirect: _91_46_46_46pageSlug_93IV93Z4RmABMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: activatedWs5YoHoFXMeta?.name ?? "account-activate___en",
    path: activatedWs5YoHoFXMeta?.path ?? "/en/account/activate",
    meta: activatedWs5YoHoFXMeta || {},
    alias: activatedWs5YoHoFXMeta?.alias || [],
    redirect: activatedWs5YoHoFXMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: activatedWs5YoHoFXMeta?.name ?? "account-activate___de",
    path: activatedWs5YoHoFXMeta?.path ?? "/konto/aktivieren",
    meta: activatedWs5YoHoFXMeta || {},
    alias: activatedWs5YoHoFXMeta?.alias || [],
    redirect: activatedWs5YoHoFXMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: activatedWs5YoHoFXMeta?.name ?? "account-activate___es",
    path: activatedWs5YoHoFXMeta?.path ?? "/es/cuenta/activar",
    meta: activatedWs5YoHoFXMeta || {},
    alias: activatedWs5YoHoFXMeta?.alias || [],
    redirect: activatedWs5YoHoFXMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: indexoXD976aAcYMeta?.name ?? "account___en",
    path: indexoXD976aAcYMeta?.path ?? "/en/account",
    meta: indexoXD976aAcYMeta || {},
    alias: indexoXD976aAcYMeta?.alias || [],
    redirect: indexoXD976aAcYMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexoXD976aAcYMeta?.name ?? "account___de",
    path: indexoXD976aAcYMeta?.path ?? "/konto",
    meta: indexoXD976aAcYMeta || {},
    alias: indexoXD976aAcYMeta?.alias || [],
    redirect: indexoXD976aAcYMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexoXD976aAcYMeta?.name ?? "account___es",
    path: indexoXD976aAcYMeta?.path ?? "/es/cuenta",
    meta: indexoXD976aAcYMeta || {},
    alias: indexoXD976aAcYMeta?.alias || [],
    redirect: indexoXD976aAcYMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93kkrZVa31eTMeta?.name ?? "account-order-orderNumber-securityKey___en",
    path: _91securityKey_93kkrZVa31eTMeta?.path ?? "/en/account/order/:orderNumber/:securityKey",
    meta: _91securityKey_93kkrZVa31eTMeta || {},
    alias: _91securityKey_93kkrZVa31eTMeta?.alias || [],
    redirect: _91securityKey_93kkrZVa31eTMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93kkrZVa31eTMeta?.name ?? "account-order-orderNumber-securityKey___de",
    path: _91securityKey_93kkrZVa31eTMeta?.path ?? "/konto/bestellung/:orderNumber/:securityKey",
    meta: _91securityKey_93kkrZVa31eTMeta || {},
    alias: _91securityKey_93kkrZVa31eTMeta?.alias || [],
    redirect: _91securityKey_93kkrZVa31eTMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: _91securityKey_93kkrZVa31eTMeta?.name ?? "account-order-orderNumber-securityKey___es",
    path: _91securityKey_93kkrZVa31eTMeta?.path ?? "/es/cuenta/compra/:orderNumber/:securityKey",
    meta: _91securityKey_93kkrZVa31eTMeta || {},
    alias: _91securityKey_93kkrZVa31eTMeta?.alias || [],
    redirect: _91securityKey_93kkrZVa31eTMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: newSzQjHbACFaMeta?.name ?? "account-password-new___en",
    path: newSzQjHbACFaMeta?.path ?? "/en/account/password/new",
    meta: newSzQjHbACFaMeta || {},
    alias: newSzQjHbACFaMeta?.alias || [],
    redirect: newSzQjHbACFaMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: newSzQjHbACFaMeta?.name ?? "account-password-new___de",
    path: newSzQjHbACFaMeta?.path ?? "/konto/passwort/neu",
    meta: newSzQjHbACFaMeta || {},
    alias: newSzQjHbACFaMeta?.alias || [],
    redirect: newSzQjHbACFaMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: newSzQjHbACFaMeta?.name ?? "account-password-new___es",
    path: newSzQjHbACFaMeta?.path ?? "/es/cuenta/contrasena/nueva",
    meta: newSzQjHbACFaMeta || {},
    alias: newSzQjHbACFaMeta?.alias || [],
    redirect: newSzQjHbACFaMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: watchlistpAwc2g74i5Meta?.name ?? "account-watchlist___en",
    path: watchlistpAwc2g74i5Meta?.path ?? "/en/account/watchlist",
    meta: watchlistpAwc2g74i5Meta || {},
    alias: watchlistpAwc2g74i5Meta?.alias || [],
    redirect: watchlistpAwc2g74i5Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: watchlistpAwc2g74i5Meta?.name ?? "account-watchlist___de",
    path: watchlistpAwc2g74i5Meta?.path ?? "/konto/merkliste",
    meta: watchlistpAwc2g74i5Meta || {},
    alias: watchlistpAwc2g74i5Meta?.alias || [],
    redirect: watchlistpAwc2g74i5Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: watchlistpAwc2g74i5Meta?.name ?? "account-watchlist___es",
    path: watchlistpAwc2g74i5Meta?.path ?? "/es/cuenta/marcadores",
    meta: watchlistpAwc2g74i5Meta || {},
    alias: watchlistpAwc2g74i5Meta?.alias || [],
    redirect: watchlistpAwc2g74i5Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93l9TKgrDZ00Meta?.path ?? "/en/movie/:movieSlug",
    children: [
  {
    name: indexTkTqSVntiOMeta?.name ?? "movie-movieSlug___en",
    path: indexTkTqSVntiOMeta?.path ?? "",
    meta: indexTkTqSVntiOMeta || {},
    alias: indexTkTqSVntiOMeta?.alias || [],
    redirect: indexTkTqSVntiOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infos9mYMChxH4oMeta?.name ?? "movie-movieSlug-infos___en",
    path: infos9mYMChxH4oMeta?.path ?? "/en/movie/:movieSlug/infos",
    meta: infos9mYMChxH4oMeta || {},
    alias: infos9mYMChxH4oMeta?.alias || [],
    redirect: infos9mYMChxH4oMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: programkLQwrGcQkxMeta?.name ?? "movie-movieSlug-program___en",
    path: programkLQwrGcQkxMeta?.path ?? "/en/movie/:movieSlug/program",
    meta: programkLQwrGcQkxMeta || {},
    alias: programkLQwrGcQkxMeta?.alias || [],
    redirect: programkLQwrGcQkxMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_93PmmccBMJXZMeta?.path ?? "/en/movie/:movieSlug/show/:showSlug",
    children: [
  {
    name: indexulhHRAzfxtMeta?.name ?? "movie-movieSlug-show-showSlug___en",
    path: indexulhHRAzfxtMeta?.path ?? "/en/movie/:movieSlug/show/:showSlug",
    meta: indexulhHRAzfxtMeta || {},
    alias: indexulhHRAzfxtMeta?.alias || [],
    redirect: indexulhHRAzfxtMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_93PmmccBMJXZMeta?.name ?? undefined,
    meta: _91showSlug_93PmmccBMJXZMeta || {},
    alias: _91showSlug_93PmmccBMJXZMeta?.alias || [],
    redirect: _91showSlug_93PmmccBMJXZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93l9TKgrDZ00Meta?.name ?? undefined,
    meta: _91movieSlug_93l9TKgrDZ00Meta || {},
    alias: _91movieSlug_93l9TKgrDZ00Meta?.alias || [],
    redirect: _91movieSlug_93l9TKgrDZ00Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93l9TKgrDZ00Meta?.path ?? "/film/:movieSlug",
    children: [
  {
    name: indexTkTqSVntiOMeta?.name ?? "movie-movieSlug___de",
    path: indexTkTqSVntiOMeta?.path ?? "",
    meta: indexTkTqSVntiOMeta || {},
    alias: indexTkTqSVntiOMeta?.alias || [],
    redirect: indexTkTqSVntiOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infos9mYMChxH4oMeta?.name ?? "movie-movieSlug-infos___de",
    path: infos9mYMChxH4oMeta?.path ?? "/film/:movieSlug/filminfos",
    meta: infos9mYMChxH4oMeta || {},
    alias: infos9mYMChxH4oMeta?.alias || [],
    redirect: infos9mYMChxH4oMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: programkLQwrGcQkxMeta?.name ?? "movie-movieSlug-program___de",
    path: programkLQwrGcQkxMeta?.path ?? "/film/:movieSlug/spielzeiten",
    meta: programkLQwrGcQkxMeta || {},
    alias: programkLQwrGcQkxMeta?.alias || [],
    redirect: programkLQwrGcQkxMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_93PmmccBMJXZMeta?.path ?? "/film/:movieSlug/vorstellung/:showSlug",
    children: [
  {
    name: indexulhHRAzfxtMeta?.name ?? "movie-movieSlug-show-showSlug___de",
    path: indexulhHRAzfxtMeta?.path ?? "/film/:movieSlug/vorstellung/:showSlug",
    meta: indexulhHRAzfxtMeta || {},
    alias: indexulhHRAzfxtMeta?.alias || [],
    redirect: indexulhHRAzfxtMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_93PmmccBMJXZMeta?.name ?? undefined,
    meta: _91showSlug_93PmmccBMJXZMeta || {},
    alias: _91showSlug_93PmmccBMJXZMeta?.alias || [],
    redirect: _91showSlug_93PmmccBMJXZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93l9TKgrDZ00Meta?.name ?? undefined,
    meta: _91movieSlug_93l9TKgrDZ00Meta || {},
    alias: _91movieSlug_93l9TKgrDZ00Meta?.alias || [],
    redirect: _91movieSlug_93l9TKgrDZ00Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    path: _91movieSlug_93l9TKgrDZ00Meta?.path ?? "/es/movie/:movieSlug()",
    children: [
  {
    name: indexTkTqSVntiOMeta?.name ?? "movie-movieSlug___es",
    path: indexTkTqSVntiOMeta?.path ?? "",
    meta: indexTkTqSVntiOMeta || {},
    alias: indexTkTqSVntiOMeta?.alias || [],
    redirect: indexTkTqSVntiOMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: infos9mYMChxH4oMeta?.name ?? "movie-movieSlug-infos___es",
    path: infos9mYMChxH4oMeta?.path ?? "infos",
    meta: infos9mYMChxH4oMeta || {},
    alias: infos9mYMChxH4oMeta?.alias || [],
    redirect: infos9mYMChxH4oMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: programkLQwrGcQkxMeta?.name ?? "movie-movieSlug-program___es",
    path: programkLQwrGcQkxMeta?.path ?? "program",
    meta: programkLQwrGcQkxMeta || {},
    alias: programkLQwrGcQkxMeta?.alias || [],
    redirect: programkLQwrGcQkxMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    path: _91showSlug_93PmmccBMJXZMeta?.path ?? "show/:showSlug()",
    children: [
  {
    name: indexulhHRAzfxtMeta?.name ?? "movie-movieSlug-show-showSlug___es",
    path: indexulhHRAzfxtMeta?.path ?? "",
    meta: indexulhHRAzfxtMeta || {},
    alias: indexulhHRAzfxtMeta?.alias || [],
    redirect: indexulhHRAzfxtMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
],
    name: _91showSlug_93PmmccBMJXZMeta?.name ?? undefined,
    meta: _91showSlug_93PmmccBMJXZMeta || {},
    alias: _91showSlug_93PmmccBMJXZMeta?.alias || [],
    redirect: _91showSlug_93PmmccBMJXZMeta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
],
    name: _91movieSlug_93l9TKgrDZ00Meta?.name ?? undefined,
    meta: _91movieSlug_93l9TKgrDZ00Meta || {},
    alias: _91movieSlug_93l9TKgrDZ00Meta?.alias || [],
    redirect: _91movieSlug_93l9TKgrDZ00Meta?.redirect || undefined,
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-22_17-54-39_d825110/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m)
  }
]