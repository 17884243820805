<template>
  <div id="app-sitefactory">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <ConsentManager />
    <LazyShowGroupsExpressBooking v-if="isExpressBookingEnabled" :cinema />
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

await useAsyncData('app:setup', () => useAppSetup({ addFavicon: true }))

const cmsConfig = useCmsConfig()

const route = useRoute()

const getRouteBaseName = useRouteBaseName()
const isExpressBookingEnabled = computed(
  () =>
    cmsConfig.value?.enableExpressBooking &&
    getRouteBaseName(route) !== ROUTE_NAMES.SHOW
)

const cinema = computed(() => cmsConfig.value?.cinemas[0] as Cinema)

defineOptions({
  name: 'AppSiteFactory',
})
</script>
